import { INTEGRATION_ID_TO_CONFIGURATION } from "@/screens/Settings/integrations";
import { useStore as useAutomationsStore } from "@/store/stores/automations";
import { useStore as useEmailsStore } from "@/store/stores/emails";
import { useStore as useOpenAPIMirrorStore } from "@/store/stores/openapi-mirror";
import { useStore as useSubscribersStore } from "@/store/stores/subscribers";
import { Permission } from "@/types/account";
import { Newsletter } from "@/types/newsletter";
import { SearchResult } from "@/types/search_result";

import { SEARCH_CONTEXT } from "./routes";

export default function (
  searchString: string,
  permissions: Permission["permissions"],
  newsletter: Newsletter
): SearchResult[] {
  if (searchString === "") {
    return [];
  }

  searchString = searchString.toLowerCase();

  const results: SearchResult[] = [];

  const emailsStore = useEmailsStore();
  for (const email of emailsStore.resource) {
    if (!email.subject.toLowerCase().includes(searchString)) {
      continue;
    }

    results.push({
      type: "email",
      text: email.subject,
      obj: email,
    });
  }

  const subscribersStore = useSubscribersStore();
  for (const subscriber of subscribersStore.resource) {
    if (!subscriber.email_address.toLowerCase().includes(searchString)) {
      continue;
    }

    results.push({
      type: "subscriber",
      text: subscriber.email_address,
      obj: subscriber,
    });
  }

  const oapiStore = useOpenAPIMirrorStore();

  const surveysStore = oapiStore["/surveys"].value;
  for (const survey of surveysStore.resource) {
    if (
      !survey.identifier.toLowerCase().includes(searchString) &&
      !survey.question.toLowerCase().includes(searchString)
    ) {
      continue;
    }

    results.push({
      type: "survey",
      text: survey.identifier,
      obj: survey,
    });
  }

  const tagsStore = oapiStore["/tags"].value;
  for (const tag of tagsStore.resource) {
    if (!tag.name.toLowerCase().includes(searchString)) {
      continue;
    }

    results.push({
      type: "tag",
      text: tag.name,
      obj: tag,
    });
  }

  const automationsStore = useAutomationsStore();
  for (const automation of automationsStore.resource) {
    if (!automation.name.toLowerCase().includes(searchString)) {
      continue;
    }

    results.push({
      type: "automation",
      text: automation.name,
      obj: automation,
    });
  }

  for (const [route, config] of Object.entries(SEARCH_CONTEXT)) {
    if (!config.title.toLowerCase().includes(searchString)) {
      if (
        !config.matches ||
        !config.matches.every((match) =>
          searchString.toLowerCase().includes(match.toLowerCase())
        )
      ) {
        continue;
      }
    }

    if (config.hide && config.hide(permissions, newsletter)) {
      continue;
    }

    results.push({
      type: config.isSpecialAction ? "special_action" : "page",
      text: config.title,
      obj: {
        id: config.title,
        name: route as any,
      },
    });
  }

  for (const [id, config] of Object.entries(INTEGRATION_ID_TO_CONFIGURATION)) {
    if (!config.name.toLowerCase().includes(searchString)) {
      continue;
    }

    results.push({
      type: "page",
      text: `Configure ${config.name} integration`,
      brandIcon: config.brandIcon,
      obj: {
        id: `Configure ${config.name} integration`,
        name: "/settings/integrations/[id]",
        params: { id },
      } as any,
    });
  }

  return results;
}
